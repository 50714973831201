
:root {
  --background-image-url: ' ';
  --background-image-align: center;
  --background-color: #fff;
  --heading-text-color: #000;
  --subheading-text-color: #000;
  --description-text-color: #000;
  --chart-text-color: #000;
  --chart-bg-color: transparent;
  --mockup-image-url: '';
  --logo-image-url: '';
}
.styler {
  position: absolute;
  top: 0;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  margin: 10px 0;
  padding: 5px;
  background: #323c47;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.styler-list,
.align,
.colorer {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.styler-input {
  background: #fff;
  color: #323c47;
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 40px;
  height: 40px;
  border-radius: 42px;
  margin: 0 5px 0 0;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
.styler-button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  background: #323c47;
  border: 0;
  fill: #fff;
  color: #fff;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  cursor: pointer;
}
.styler-button:hover {
  background: #283039;
}
.styler-selector {
  margin: 0 5px;
}
.styler.is-visible {
  visibility: visible;
  opacity: 1;
}
.styler .input-group {
  margin: 5px;
}
.align {
  height: 42px;
}
.colorer {
  height: 42px;
}
.colorer li >input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  border: 4px solid #283039;
  margin: 0 5px;
  outline: none;
}
.colorer li >input:checked {
  border-color: #526375;
}
.colorer li >input:hover {
  border-color: #526375;
}
.colorer li >input#colorRed {
  background: #ff3d3d;
}
.colorer li >input#colorBlue {
  background: #0072ff;
}
.colorer li >input#colorGreen {
  background: #18d88b;
}
.colorer li >input#colorBlack {
  background: #000;
}
.colorer li >input#colorWhite {
  background: #fff;
}
.is-hidden {
  display: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
