
.modal[data-v-029cf0ee] {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 309;
  background-color: transparent;
  box-shadow: none;
  margin: 0 auto;
  font-family: 'Raleway', Arial;
}
.modal__backdrop[data-v-029cf0ee] {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 301;
}
.modal__dialog[data-v-029cf0ee] {
  background-color: #fff;
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 302;
  overflow-y: scroll;
}
@media screen and (max-width: 992px) {
.modal__dialog[data-v-029cf0ee] {
    width: 90%;
}
}
.modal__close[data-v-029cf0ee] {
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
}
.modal__header[data-v-029cf0ee] {
  padding: 20px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modal__body[data-v-029cf0ee] {
  padding: 10px 20px 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.modal__footer[data-v-029cf0ee] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 20px;
}
.modal__footer[data-v-029cf0ee]:empty {
  padding: 0;
}
.fade-enter-active[data-v-029cf0ee],
.fade-leave-active[data-v-029cf0ee] {
  transition: opacity 0.2s;
}
.fade-enter[data-v-029cf0ee],
.fade-leave-to[data-v-029cf0ee] {
  opacity: 0;
}
