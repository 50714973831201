
.vuse-icon {
  display: block;
  width: 20px;
  height: 20px;
}
.controller-button:hover,
.menu-element:hover {
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.125), 0 10px 10px rgba(0,0,0,0.1);
}
.nav {
  display: none;
  height: 70px;
  justify-content: space-between;
  padding: 15px 25px;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(50,60,71,0.2);
  z-index: 105;
  width: 100%;
}
.nav.is-visible {
  display: flex;
}
.nav .nav-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 1000px) {
.nav {
    height: auto;
    align-items: center;
}
.nav img {
    width: 175px;
}
.nav .ui.selection.dropdown {
    margin-right: 25px !important;
}
#download-button {
    width: 70px;
}
#download-button-text {
    display: none;
}
.controller-intro__divider-container__content ul {
    flex-direction: column;
}
.controller-intro__divider-container__content ul li {
    justify-content: center;
    margin-bottom: 25px;
}
}
@media only screen and (max-width: 550px) {
.ui.selection.dropdown>.dropdown.icon {
    margin: 0 !important;
    padding: 0 !important;
}
.nav {
    flex-direction: column;
}
.nav .ui.selection.dropdown {
    margin: 15px 15px 0 0 !important;
    width: 100% !important;
}
.nav .controller-button {
    margin-top: 15px;
    padding: 10px 15px;
    justify-content: center !important;
}
}
.editor-container {
  display: flex;
  height: 100%;
}
.artboard-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  position: relative;
}
.artboard:empty {
  display: none;
}
.artboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}
.artboard.is-editable {
  outline: none;
}
.artboard.is-editable:hover {
  box-shadow: none;
}
.controller {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.controller-panel {
  position: fixed;
  z-index: 200;
  top: 25px;
  right: 25px;
}
.controller-input {
  outline: none;
  border: 1px solid #c1c1c1;
  padding: 0.5em 1em;
  margin: 20px 0;
  border-radius: 40px;
  width: 100%;
  font-size: 16px;
}
.controller-input:focus {
  border-color: #0072ff;
  box-shadow: 0 0 0 2px rgba(0,114,255,0.5);
}
.controller-button {
  display: none;
  transition: 0.2s;
  border: none;
  outline: none;
  border-radius: 50px;
  padding: 10px 25px;
  color: #fff;
  fill: #fff;
  font-size: 16px;
  white-space: nowrap;
}
.controller-button svg {
  transition: 0.2s;
}
.controller-button:not(:last-child) {
  margin-right: 20px;
}
.controller-button.is-rotated >svg {
  transform: rotate(45deg);
}
.controller-button.is-blue {
  background-color: #0072ff;
}
.controller-button.is-blue:hover {
  background-color: #005bcc;
}
.controller-button.is-red {
  background-color: #ff3d3d;
}
.controller-button.is-red:hover {
  background-color: #fd0000;
}
.controller-button.is-green {
  background: linear-gradient(65deg, #01ba9f 0, #72ddcd 100%);
  background-origin: border-box;
}
.controller-button.is-green:hover {
  background-color: #00a261;
}
.controller-button.is-dark {
  background-color: #323c47;
}
.controller-button.is-dark:hover {
  background-color: #283039;
}
.controller-button.is-gray {
  background-color: $gary;
}
.controller-button.is-gray:hover {
  background-color: #9a9a9a;
}
.controller-button.is-visible {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.controller-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
.controller-button:disabled:hover {
  background: #ccc;
  cursor: not-allowed;
}
.controller-intro {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 70px 50px;
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
}
.controller-intro::after {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 920px;
  background-image: url("https://www.sizechart.io/assets/intro-gradient.svg");
  background-repeat: no-repeat;
  background-size: auto;
  left: 50%;
  top: 0;
  background-position: center top;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: -1;
}
.controller-intro__top-content {
  width: 100%;
  margin: 75px 0 50px 0;
  text-align: center;
  font-size: 24px;
  color: #323c47;
}
.controller-intro__divider-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: -5px;
  z-index: 1;
}
.controller-intro__divider-container__content {
  display: flex;
  justify-content: center;
  padding: 50px;
  width: 100%;
  max-width: 1100px;
  border-top: 1px solid #e2e2e2;
  background: #fff;
}
.controller-intro__divider-container__content ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  width: 100%;
}
.controller-intro__divider-container__content ul li {
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
}
.controller-intro h1 {
  color: #fff;
  font-size: 36px;
  font-weight: bolder;
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
}
.controller-intro .logo {
  max-width: 250px;
  position: absolute;
  top: 20px;
  left: 30px;
}
.controller-intro .controller-theme {
  max-width: 400px;
  color: #fff;
}
.controller-themes {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.controller-theme {
  background: linear-gradient(65deg, #01ba9f 0, #72ddcd 100%);
  background-origin: border-box;
  color: #fff;
  transition: 0.2s;
  border: none;
  outline: none;
  margin: 25px 0;
  padding: 20px;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  font-size: 22px;
  outline: none;
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.controller-theme:hover {
  box-shadow: 0 14px 28px #2f4f4f, 0 10px 10px #2f4f4f;
}
.controller-theme:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.menu {
  margin: 0;
  user-select: none;
  -moz-user-select: none;
  width: 20%;
  max-width: 300px;
  background: #fff;
  padding: 20px 10px;
  display: none;
  flex-direction: column;
  list-style: none;
  box-shadow: 1px 0 10px rgba(50,60,71,0.2);
}
.menu-header {
  cursor: pointer;
}
.menu.is-visible {
  display: none;
}
.menu-body {
  display: none;
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}
.menu-group .menu-body {
  width: 90%;
  margin: 10px auto;
}
.menu-group.is-visible .menu-body {
  display: block;
}
.menu-icon {
  width: 24px;
  height: 24px;
  fill: #c1c1c1;
  transition: 0.2s;
}
.menu-group.is-visible .menu-icon {
  transform: rotate(180deg);
}
.menu-element {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  background: #aeaeae;
  transition: 0.3s;
  cursor: pointer;
  color: #fff;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
}
.menu-element:not(:last-child) {
  margin-bottom: 10px;
}
.menu-elementImage {
  max-width: 100%;
  pointer-events: none;
}
.menu-elementTitle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
  text-align: center;
  padding: 5px;
}
.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}
.sortable-ghost {
  opacity: 0.3;
  box-shadow: 0 0 2px 1px #0072ff;
}
.is-editable:hover {
  box-shadow: inset 0 0 0 2px #0072ff;
}
.fraction > sup,
.fraction > sub {
  font-size: 0.66em;
}
.fraction {
  font-size: 14px;
  position: absolute;
  top: 10px;
  margin-left: 2px;
}
.fraction > sup {
  vertical-align: 0.4em;
}
.fraction > sub {
  vertical-align: -0.2em;
}
.ui.fluid.dropdown {
  display: flex !important;
  align-items: center;
  width: 100% !important;
  max-width: 400px;
  margin: 0 25px;
}
.ui.selection.dropdown>.dropdown.icon {
  top: unset !important;
}
input.search {
  height: 100%;
}
.footer-links-container {
  display: none;
  position: fixed;
  bottom: 0;
  font-size: 14px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgba(50,60,71,0.2);
  padding: 5px 25px;
  z-index: 205;
}
.footer-links-container ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-links-container ul li {
  margin-right: 15px;
}
.is-visible + .artboard-container + .footer-links-container {
  display: flex;
}
.is-visible + .artboard-container {
  background-color: #fff;
}
.emoji {
  font-size: 35px;
  margin-right: 15px;
}
#donate-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
}
#donate-button-container #donate-button {
  width: 300px;
}
