
.vuse-icon {
  display: block;
  width: 20px;
  height: 20px;
}
.uploader {
  position: relative;
  outline: none;
}
.uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}
.uploader >img {
  width: 100%;
  display: block;
}
.uploader:hover {
  box-shadow: 0 0 0 2px #0072ff;
}
.uploader.move > img {
  cursor: move;
}
.uploader.move > img:hover {
  box-shadow: 0 0 0 2px #0072ff;
}
